import React from "react"
import Photos from '../components/photos.js'
import { useAuth0 } from "@auth0/auth0-react"
import '../styles/loggedout.sass'

const WeddingAlbum = () => {
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0()
  console.log({ isAuthenticated, user })

  return (
    <div className="album-parent">
      {isAuthenticated ? (
        <main className="gallery-main">
          <header className="gallery-header">
            <h4 className="logo">Wedding Album</h4>
            <nav><p>Hi, {user?.email} <span onClick={() => logout()}>Log out</span></p></nav>
          </header>
          <Photos/>
        </main>
      ) : (
        <main className="loggedout">
          <h2>Decisive Moments</h2>
          <button onClick={() => loginWithRedirect({})}>Open Gallery</button>
          
        </main>
      )}
    </div>
  )
}

export default WeddingAlbum