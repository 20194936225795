import React from "react"
import "../styles/photos.sass"
import aws from 'aws-sdk';

class Photos extends React.Component {
  constructor(){
    super()
    this.state = { Photoss: [] };
    this.popup = this.popup.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.state = {
      isOpen: false
    }
    
  };

  componentDidMount() {
    this.renderPosts();
  }
  renderPosts = async() => {
    try{
      aws.config.setPromisesDependency();
      aws.config.update({
        accessKeyId: process.env.GATSBY_ACCESS_KEY_ID,
        secretAccessKey: process.env.GATSBY_SECRET_ACCESS_KEY,
        region: process.env.GATSBY_REGION

      })

      const s3 = new aws.S3();
      const response = await s3.listObjectsV2({
        Bucket: 'mofamphotos'
      }).promise();


      this.setState({
        Photoss: response.Contents.map((photoData, i) => (
          <li  onClick={() => this.popup(photoData.Key)} key={i} className="list-group-item"><img src={'https://mofamphotos.s3-ap-southeast-2.amazonaws.com/' + photoData.Key} alt={photoData.Key}/></li>
        ))
        
      });
      
    }catch(e){
      console.log('Our Error', e)
    }
    
  }
  popup(id){
    this.setState({ 
      isOpen: !this.state.isOpen,
      url: id
    });
  };
  closePopup(){
    this.setState({ 
      isOpen: !this.state.isOpen,
      url: ''
    });
  }
  
  render(){
      
    return(
    
      <section className="gallery-main">
       <ul className="gallery-container"> {this.state.Photoss} </ul>
       {this.state.isOpen &&(<div className="popup"><div><div onClick={this.closePopup} className="close-popup-bg"></div><img src={'https://mofamphotos.s3-ap-southeast-2.amazonaws.com/' + this.state.url} /><span className="close-popup" onClick={this.closePopup}>X</span></div></div>)}    
      </section>
    )
  
  }
  
}

export default Photos